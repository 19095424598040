import Link from 'next/link';
import styled from 'styled-components';
import Image from "next/image";
import { darken, lighten, transparentize } from 'polished';
import { Tiles } from '@rebass/layout';
import { useTranslations } from 'next-intl';

import { media } from '@/themes';
import { colorForCategory } from '@/utils/category';

const Title = styled.div`
  font-size: 1em;
  font-weight: 600;
  margin-top: 1.2em;
`;

const StepContainer = styled.a`
  background-color: ${props => props.$background ? props.$background : transparentize(.97, props.color)};
  border-radius: 10px;
  box-shadow: ${props => props.theme.shadows.primary};
  color: ${props => props.theme.colors.typography.secondary};
  display: block;
  font-family: ${({ theme }) => theme.fonts.title};
  padding: 20px;
  text-align: center;
  transition-duration: 500ms, 250ms;
  transition-property: background-color, box-shadow;

  svg {
    color: ${props => props.theme.name === 'dark' ? lighten(0.2, props.color) : props.color};
  }

  &:visited {
    color: ${props => props.theme.colors.typography.secondary};
  }

  &:hover {
    color: white;
    background-color: ${props => props.$background ? props.$background : transparentize(.9, props.color)};
    box-shadow: ${props => props.theme.shadows.hover};

    svg {
      color: white;
    }

    ${Title} {
      color: ${props => darken(.05, props.color)};
    }
  }
`;

const Container = styled.div`
  color: white;
  padding: 3em 0;
  font-weight: 400;
`;

const InnerContainer = styled.div`
  margin: 0 auto;
  max-width: ${props => props.theme.maxContentWidth};

  ${media.xl`
    padding: 0 1em;
  `};
`;

export const Category = ({ background, category, currency, IconComponent, }) => {
  const t = useTranslations('categories');

  return (
    <Link passHref href={`/shop/${category.slug}`} legacyBehavior>
      <StepContainer $background={background} color={colorForCategory(category)} title={currency && `Buy ${category.name} gift cards with ${currency.displayName} / Spend your ${currency.displaySymbol} on ${category.name}`}>
        {IconComponent}
        <Title>{t(category.slug)}</Title>
      </StepContainer>
    </Link>
  );
};

export const CategoryIcon = ({ category, size }) => {
  const t = useTranslations('categories');

  return (
    <Image
      alt={t(category.slug || category.name)}
      height={size}
      src={require(`@images/categories/icons/${category.slug}.png`)}
      unoptimized
      width={size}
      style={{
        maxWidth: '100%',
        height: 'auto',
        margin: 'auto'
      }} />
  );
};

const CategoriesGrid = ({ background = '#333', categories, currency }) => (
  <Container $background={background}>
    <InnerContainer>
      <Tiles columns={[2, 3, 4, 6]}>
        {categories.map(category =>
          <Category
            key={category.id}
            currency={currency}
            category={category}
            IconComponent={
              <CategoryIcon category={category} size={44} />
            } />
        )}
      </Tiles>
    </InnerContainer>
  </Container>
);

export default CategoriesGrid;
